@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 auto;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: colorChange 2s ease-in-out;
}

header {
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  top: 0;
  background-size: cover;
  background-position: 80%;
  z-index: 9999;
}

header nav ul li a {
  padding: 10px 15px;
}

header a {
  text-decoration: none;
}

@counter-style parentheses {
  system: cyclic;
  symbols: ")";
  suffix: " ";
}

ol {
  list-style-type: decimal;
  margin-left: 20px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

.nav-panel {
  /* -webkit-animation: slide 0.5s forwards; */
  animation: slide 0.5s forwards;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(25px);
}

/* @-webkit-keyframes slide {
    100% { right: 0; }
} */

@keyframes slide {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

/* Hamburger Menu */
.menuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 13%;
  color: #090909;
  width: 3.5em;
  height: 3.5em;
  border-radius: 0.5em;
  transition: all 0.3s;
}

.menuButton > input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.menuButton span {
  width: 30px;
  height: 4px;
  background: #131313;
  border-radius: 100px;
  transition: 0.3s ease;
}

input[type]:checked ~ span.top {
  transform: translateY(10px) rotate(45deg);
  width: 40px;
  background-color: #94a3b8;
}

input[type]:checked ~ span.bottom {
  transform: translateY(-10px) rotate(-45deg);
  width: 40px;
  box-shadow: 0 0 10px #495057;
  background-color: #f1f5f9;
}

input[type]:checked ~ span.middle {
  transform: translateX(-20px);
  opacity: 0;
}
/* END Hamburger menu */

/* START Carousel */

/* End Carousel */

.tile {
  border: 2px solid;
  /* border-radius: 15px; */
  box-shadow: 4px 4px 0 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.tile:hover {
  box-shadow: 2px 2px 0 0;
  transform: translate(2px, 2px);
}

.tile:active {
  box-shadow: 2px 2px 0 0;
  transform: translate(2px, 2px);
}

.fadein {
  animation: fadein 2s;
  -moz-animation: fadein 2s;
  /* Firefox */
  -webkit-animation: fadein 2s;
  /* Safari and Chrome */
  -o-animation: fadein 2s;
  /* Opera */
}

.collapsed {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.expanded {
  max-height: 500px; /* you may need to adjust this value */
  transition: max-height 0.2s ease-in-out;
}

.expand-enter {
  opacity: 0.01;
  max-height: 0;
}

.expand-enter-active {
  opacity: 1;
  max-height: 500px; /* or whatever max-height you want */
  transition: opacity 300ms ease-in, max-height 300ms ease-in;
}

.expand-exit {
  opacity: 1;
  max-height: 500px; /* or whatever max-height you want */
}

.expand-exit-active {
  opacity: 0.01;
  max-height: 0;
  transition: opacity 300ms ease-in, max-height 300ms ease-in;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideInFromTop 1s ease-out;
}

@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* START MY ICON ANIMATION */
#my-icon {
  animation: intro-transform 2s linear 0s 1 normal none;
  -moz-animation: intro-transform 1s linear 1s 1 normal none;
  /* Firefox */
  -webkit-animation: intro-transform 1s linear 1s 1 normal none;
  /* Safari and Chrome */
  -o-animation: intro-transform 1s linear 1s 1 normal none;
  /* Opera */
}
@keyframes intro-transform {
  0% {
    transform: scale(3) translate(25vw, 25vh);
    border: #f97316;
  }
  100% {
  }
}
@-moz-keyframes intro-transform {
  /* Firefox */
  0% {
    transform: translate(100px);
  }
  100% {
    transform: translate(0px);
  }
}
@-webkit-keyframes intro-transform {
  /* Safari and Chrome */
  0% {
    transform: scale(2) translate(50vw);
  }
  100% {
  }
}
@-o-keyframes intro-transform {
  /* Opera */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/* END MY ICON ANIMATION */

/* START INTRO FADE */
.intro-fade {
  animation: fadein 1s;
  -moz-animation: fadein 2s;
  /* Firefox */
  -webkit-animation: fadein 2s;
  /* Safari and Chrome */
  -o-animation: fadein 2s;
  /* Opera */
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* END INTRO FADE */

.expand-enter {
  opacity: 0.01;
  max-height: 0;
}

.expand-enter-active {
  opacity: 1;
  max-height: 500px; /* or whatever max-height you want */
  transition: opacity 300ms ease-in, max-height 300ms ease-in;
}

.expand-exit {
  opacity: 1;
  max-height: 500px; /* or whatever max-height you want */
}

.expand-exit-active {
  opacity: 0.01;
  max-height: 0;
  transition: opacity 300ms ease-in, max-height 300ms ease-in;
}